<template>
	<section class="container">
		<template v-if="canHandleAuth">
			<div>
				<h1>{{ $t('global.user.account') }}</h1>
			</div>

			<div class="row mt-2 mb-2">
				<div class="col-12">
					<hr />
				</div>
			</div>

			<create-account v-if="!userAuth" />
			<!-- Change Email -->
			<change-email v-if="userAuth" />
			<!-- Change password -->
			<change-password v-if="userAuth" />
		</template>

		<div>
			<h1>{{ $t('global.user.info') }}</h1>
		</div>
		<div class="row mt-2 mb-2">
			<div class="col-12">
				<hr />
			</div>
		</div>

		<div>
			<b-form @submit.prevent="onSubmit">
				<b-form-group id="signup-emailForm">
					<div class="row mb-2">
						<div class="col-lg-6 col-12">
							<label>
								{{ $t('signup.firstName') }}
							</label>
							<InputText v-model="user.firstName" :required="true" />
						</div>

						<div class="col-lg-6 col-12">
							<label>
								{{ $t('signup.lastName') }}
							</label>
							<InputText v-model="user.lastName" :required="true" />
						</div>
						<div class="col-lg-6 col-12">
							<label>
								{{ $t('signup.cpr') }}
							</label>
							<InputText v-model="user.cpr" :required="true" />
						</div>
						<br />
					</div>
					<div class="row mb-2">
						<div class="col-lg-6 col-12">
							<label>
								{{ $t('signup.email') }}
							</label>
							<InputText v-model="user.email" :disabled="true" />
						</div>
						<div class="col-lg-6 col-12">
							<label>
								{{ $t('signup.seconaryEmail') }}
							</label>
							<InputText v-model="user.emailSecondary" />
						</div>
						<div class="col-lg-6 col-12">
							<label>
								{{ $t('signup.phone') }}
							</label>
							<InputPhone v-model="user.phone" />
						</div>
						<div class="col-lg-6 col-12">
							<label>
								{{ $t('global.user.phoneSecondary') }}
							</label>
							<InputPhone v-model="user.phoneSecondary" />
						</div>
						<div class="col-lg-6 col-12" v-if="$can('read', 'User', 'Description')">
							<label>
								Beskrivelse/kommentar fra rådgiver
							</label>
							<InputTextArea v-model="user.description" :required="false" />
						</div>
					</div>

					<BaseActionButton v-bind:clicked="clicked" v-bind:disabled="clicked" type="submit" id="signupButton" class="mt-2">
						{{ $t('global.button.update') }}
					</BaseActionButton>
				</b-form-group>
			</b-form>
		</div>
	</section>
</template>
<script>
import InputText from '@/modules/global/atomComponents/InputText.vue'
import InputTextArea from '@/modules/global/atomComponents/InputTextArea.vue'
import ChangeEmail from '@/modules/general/user/components/ChangeEmail'
import ChangePassword from '@/modules/general/user/components/ChangePassword'
import CreateAccount from '@/modules/general/user/components/CreateAccount'
import InputPhone from '@/modules/global/atomComponents/InputPhone.vue'

export default {
	name: 'info',
	components: {
		InputText,
		ChangeEmail,
		ChangePassword,
		CreateAccount,
		InputPhone,
		InputTextArea
	},
	data() {
		return {
			clicked: false,
			password: '',
		}
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	methods: {
		async onSubmit() {
			try {
				this.clicked = true
				console.log('User update attempting')
				await this.$store.dispatch('userVuex/updateUser', {
					id: this.id,
					data: this.user,
				})
				this.toast('Success', 'Brugeren er nu opdateret', true)
				this.clicked = false
			} catch (err) {
				console.log(err)
				this.toast('Fejl', err, false)
			}
		},
	},
	computed: {
		canHandleAuth() {
			if (this.isMe) {
				return true
			} else {
				return this.$can('update', 'User', 'Account')
			}
		},
		companyOptions() {
			var options = this.companies.map((item) => {
				return {
					text: item.companyInfo.name,
					value: item.id,
				}
			})
			return options
		},
		companies() {
			var companies = this.$store.getters['company/companies']
			return companies
		},
		user() {
			return this.$store.getters['userVuex/user'] ?? null
		},
		id() {
			return this.$route.params.id
		},
		userAuth() {
			return this.$store.getters['userVuex/userAuth']
		},
		userAuthEmail() {
			return this.userAuth?.email ?? null
		},
		isMe() {
			return this.id == this.myUid
		},
		myUid() {
			return this.$store.getters['auth/uid']
		},
	},
}
</script>
